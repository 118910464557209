/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', "Helvetica Neue", sans-serif;
}
